@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
a {
  text-decoration: none !important;
}

body {
  font-family: "Poppins", sans-serif !important;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  scroll-behavior: smooth;
  background: #ffffff !important;
}

html {
  --scrollbarBG: #073c7c;
  --thumbBG: #ffffff;
  --primary: #073c7c;
  --white: #ffffff;
  --Black: #000000;
}

.NewHome .headLabel {
  background: hsl(212, 100%, 88%);
  color: var(--Black);
  padding: 8px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 16px;
}

.NewHome .card div.card-body,
.ViewContractors .card div.card-body {
  display: flex !important;
  padding: 10px 16px;
  align-items: center;
  justify-content: space-around;
}

.property-div {
  flex-wrap: nowrap;
}

.New-card {
  padding: 20px 16px !important;
}

.new-card-label {
  font-size: small;
  color: gray;
}

.border-th {
  border-right: 2px solid white !important;
}

.border-th-new {
  border-right: 1px solid white !important;
}

.border-tr {
  border-right: 2px solid #073c7c !important;
}

.border-tr-new {
  border-right: 1px solid gray !important;
}

.NewHome .card div.card-body img,
.ViewContractors .card div.card-body img {
  width: 50px;
  height: 50px;
}

.NewHome .card .data,
.ViewContractors .card .data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 16px !important;
}

.NewHome .card h4,
.ViewContractors .card h4 {
  font-weight: 700;
  margin-bottom: 4px;
}

.NewHome .card h6,
.SurveyImages .card h6 {
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

.PageNotFound {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.PageNotFound h1 {
  font-weight: 600;
}

.pagination {
  width: 100% !important;
}

.pagination .prev,
.pagination .prev:focus,
.pagination .next,
.pagination .next:focus {
  background: transparent !important;
  border: none !important;
  color: var(--FoxBlack) !important;
  transition: 0.3s !important;
}

.pagination .prev:hover {
  background: transparent;
  border: none;
  color: var(--FoxBlack);
  transform: translateX(-5px);
}

.pagination .next:hover {
  background: transparent;
  border: none;
  color: var(--Black);
  transform: translateX(5px);
}

.pagination .activePageButton {
  border: 2px solid var(--Black) !important;
  color: var(--Black) !important;
  background: var(--White) !important;
  transition: 0.1s !important;
  font-weight: 600;
}

.pagination .pageButton {
  border: 1px solid transparent !important;
  color: var(--Black) !important;
  background: transparent !important;
  transition: 0.1s !important;
}

.pagination select.form-select {
  border: 1px solid #6f6f6f;
  transition: 0.3s;
}

label.form-label {
  color: #575757 !important;
  font-size: 14px !important;
  margin-bottom: 4px !important;
  font-weight: 600 !important;
  display: flex !important;
  align-items: center !important;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

label.form-label span {
  color: red !important;
}

.errMsg {
  font-size: 12px !important;
  color: red !important;
  margin: 2px 0px 5px 0px !important;
}

/*------------------- All Buttons -------------------*/
.btn-login {
  background: linear-gradient(145deg, #1267cf, #073c7c) !important;
  border-radius: 12px !important;
  border: 1px solid transparent !important;
  transition: 0.3s !important;
}

.btn-submit {
  background: linear-gradient(145deg, #1267cf, #073c7c) !important;
  padding: 4px 12px;
  border-radius: 12px !important;
  border: none !important;
  transition: 0.2s !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.btn-logout {
  background: transparent !important;
  padding: 4px 12px;
  border-radius: 12px !important;
  border: none !important;
  transition: 0.2s !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.btn-logout:hover {
  transform: translateX(-5px);
  background: transparent;
  color: black;
  border: none !important;
}

.btn-submit:hover {
  transform: scale(1.04) !important;
  background: linear-gradient(145deg, #1267cf, #073c7c);
}

.btn-cancel {
  background: transparent !important;
  color: #073c7c !important;
  border: 1px solid #073c7c !important;
  border-radius: 12px !important;
  padding: 5px 11px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.btn-update {
  background: linear-gradient(145deg, #1267cf, #073c7c) !important;
  padding: 4px 12px;
  border-radius: 12px !important;
  border: 1px solid transparent !important;
}

.viewCallRec {
  padding: 4px 12px !important;
  border: none !important;
  border-radius: 10px !important;
  background: #ffffff;
  font-size: 12px;
  color: #073c7c;
  margin-left: 12px !important ;
}

/* --- Login CSS ----*/

.loginNew {
  height: 100vh !important;
  width: 100vw !important;
}

.loginNew .left {
  background: url(./Assets/PCMC.jpg) center center no-repeat;
  background-size: cover;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.loginNew h2 {
  background: linear-gradient(180deg, #4c4c4c, #000000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
}

.loginNew .right .card {
  border: 0px;
  border-radius: 15px;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  width: 100%;
}

.loginNew .right input {
  /* border: none none none none !important; */
  border-color: transparent transparent #363434 transparent !important;
  border-radius: 0px !important;
}

.loginNew .right .input-group-text {
  /* border: none none none none !important; */
  border-color: transparent transparent #363434 transparent !important;
  border-radius: 0px !important;
  padding: 4px 10px !important;
  background: transparent;
  margin-left: -2px;
}

.loginNew .right input:focus {
  outline: none;
  box-shadow: none;
}

/* footer css */

@media (max-width: 992px) {
  .loginNew .right .card {
    width: 100%;
  }
}

.loginNew .right input {
  border-radius: 12px;
}

.loginNew .right span {
  border-radius: 12px;
}

.refresh {
  color: #073c7c !important;
  transition: 0.3s;
}

.refresh:hover {
  cursor: pointer;
  transform: translateX(-3px);
  color: #073c7c;
}

.uploadedFile{
  position: relative !important;
  padding: 8px !important;
}

 .uploadedFile .removeIcon{
  color: red ;
  position: absolute ;
  top: -7px ;
  right: -7px ;
  transition: 0.2s;
}

.uploadedFile .removeIcon:hover{
  cursor: pointer;
  transform: scale(1.19);

}

.uploadFile {
  width: max-content;
  height: auto;
  overflow: hidden;
  border: 2px dashed rgb(199, 199, 199);
  margin-top: 6px;
  padding: 6px 12px;
  margin: auto;
}

nav {
  position: fixed;
  left: 42vh;
  right: 0vh;
  background: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 20px;
  z-index: 1000;
}

nav h5 {
  color: black;
  font-weight: 600;
}

nav ul {
  display: flex;
  list-style: none;
  padding: 0px;
  margin: 0px;
}

nav ul li {
  letter-spacing: 1px;
  font-weight: 400;
  padding: 2px 10px;
  transition: 0.2s;
  display: inline-block;
  color: var(--yellow-brand-color);
}

nav ul li:hover {
  transform: translateY(-3px);
  color: white;
}

@media (max-width: 992px) {
  .lg-nav {
    display: none;
  }

  .mobile-nav {
    position: fixed;
    --gap: 2rem;
    border-radius: 0px;
    inset: 0 0 0 20%;
    z-index: 1000;
    flex-direction: column;
    padding: 20% 5% 20% 0px;
    transform: translateX(100%);
    transition: 0.3s;
    list-style: none;
    margin: 0;
    background: var(--primary);
  }
  .mobile-nav li {
    margin-bottom: 15px;
    color: yellow;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  .mobile-nav[data-visible="true"] {
    transform: translateX(0%);
  }

  .mobile-nav-toggle {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 30px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    /* border: 3px solid #fff; */
  }
  .mobile-nav-toggle .menu-btn__burger {
    width: 35px;
    height: 3px;
    background: #004686;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
    z-index: 9999;
  }
  .mobile-nav-toggle .menu-btn__burger::before,
  .mobile-nav-toggle .menu-btn__burger::after {
    content: "";
    position: absolute;
    width: 36px;
    height: 3px;
    background: #004686;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
  }
  .mobile-nav-toggle .menu-btn__burger::before {
    transform: translateY(-10px);
  }
  .mobile-nav-toggle .menu-btn__burger::after {
    transform: translateY(10px);
  }

  .mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger {
    transform: translateX(-50px);
    background: transparent;
    box-shadow: none;
  }
  .mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger::before {
    transform: rotate(45deg) translate(35px, -35px);
    background: var(--white);
  }
  .mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger::after {
    transform: rotate(-45deg) translate(35px, 35px);
    background: var(--white);
  }
}

/* ------ SideBar-lg CSS for Large or Desktop Devices ------ */
@media (min-width: 992px) {
  .lg-nav {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .PopupData div {
    display: flex;
    align-items: center;
    font-family: "Poppins", sans-serif !important;
    margin-top: 5px;
  }

  .PopupData div p {
    margin-bottom: 0px;
  }

  .mobile-nav {
    display: none;
  }
  .mobile-nav-toggle {
    display: none;
  }

  .sidenav-lg {
    height: 100vh;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background: var(--primary);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    padding-right: 2px;
    width: 42vh;
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
    scroll-behavior: smooth;
    /* --scrollbarBG: rgb(0, 0, 0);
    --thumbBG: #004686; */
  }

  .sidenav-lg .menus {
    overflow-y: auto;
    transition: overflow-y 0.5s ease;
    height: 72vh;
    padding-right: 8px;
  }

  .sidenav-lg .menus::-webkit-scrollbar {
    width: 8px;
  }

  .sidenav-lg .menus::-webkit-scrollbar-track {
    background: #073c7c;
  }

  .sidenav-lg .menus::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    border-radius: 6px;
    border: 3px solid #073c7c;
  }

  .sidenav-lg .logoBg {
    background: white;
    border-radius: 0px 0px 12% 12%;
    padding: 10px;
    width: max-content;
    margin: auto;
    text-align: center;
  }

  .sidenav-lg hr {
    border-width: 2px;
    color: var(--White);
    width: 40%;
    margin: auto;
    opacity: 1;
  }

  .outletDiv {
    position: absolute;
    left: 42vh;
    right: 2vh;
    top: 10vh;
  }
}

@media (max-width: 992px) {
  .outletDiv {
    position: absolute;
    left: 1vh;
    right: 1vh;
    top: 8vh;
  }

  nav {
    left: 0;
    right: 0;
  }
}
/* --- SideBar Accordion CSS for all sizes devices ----*/
.accordion {
  background-color: transparent !important;
}

.accordion .accordion-item {
  border: #000000;
}

.accordion .accrodion-body {
  background: transparent !important;
  box-shadow: none !important;
  border: none !important;
}

.accordion-item .accordion-header {
  background: var(--primary) !important;
  border: none !important;
  padding: 0px;
  box-shadow: none;
  color: var(--white) !important;
  border: none !important;
}

.accordion .activeMenu .accordion-button {
  color: var(--primary) !important;
  background: var(--white) !important;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  font-weight: 600 !important;
}

.accordion .dropDown {
  border-radius: 0px 0px 12px 0px !important;
  background: var(--white);
}

.accordion .activeMenu.dropDown .accordion-button {
  border-radius: 0px 12px 0px 0px !important;
}

.accordion .accordion-button {
  background: transparent !important;
  color: var(--white) !important;
  border: none !important;
  font-weight: 400 !important;
  border-radius: 0px 12px 12px 0px !important;
  box-shadow: none !important;
  padding: 14px 20px;
}

.accordion .accordion-button:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  /* color: #FFFFFF !important; */
  /* background: linear-gradient(145deg, #1267cf, #073c7c) !important;
  color: #ffffff !important; */
}
.accordion-item .accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%0ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion .newMenu .accordion-button:after {
  /* margin-right: 2px; */
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion-item .accordion-button:focus:after {
  box-shadow: none;
  outline: #000000 !important;
  border: none !important;
  /* background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%0ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important; */
}
.accordion .dropDown .accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion .activeMenu.dropDown .accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23073c7c'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordionNav {
  border: 1px solid var(--primary) !important;
  color: var(--primary) !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 10px !important;
  font-weight: 500 !important;
  background: transparent !important;
}

.accordionNavActive {
  border: 1px solid transparent !important;
  color: var(--white) !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 10px !important;
  font-weight: 500 !important;
  background: var(--primary) !important;
}

/* -------- Visitor Report CSS ---------------*/
/* .visitReport .VisitorMainCard{
  box-shadow: rgb(204, 219, 232) 2px 2px  6px 2px inset, rgba(255, 255, 255, 0.5) -3px -3px  6px 1px inset ;
  border: none;
  border-radius: 12px;
  padding: 10px 15px;
}
.visitReport .visitors{
 max-height: 65vh;
  overflow-y: scroll;
  padding: 0px;
  margin: 0px;
}

.visitReport .visitors .card{
  border: 1px solid #073c7c;
  border-radius: 12px;
  padding: 10px 15px;
  transition: 0.1s;
}

.visitReport .visitors .card .btn-location{
    background:transparent ;
    border: 1px solid #073c7c ;
    border-radius: 10px ;
    font-size: 12px ;
    color: #073c7c ;
    padding: 4px 8px ;
}

.visitReport .visitors .card:hover{
  background: linear-gradient(145deg, #1267cf, #073c7c);
  color: white;
}

.visitReport .visitors .card:hover .btn-location{
  background: #ffffff !important;
  color:  red !important;
}

.visitReport .visitors::-webkit-scrollbar {
  width: 10px;
}

.visitReport .visitors::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
  border-radius: 10px;
}

.visitReport .visitors::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

.visitReport .visitors .card div{
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.visitReport .visitors .card h6{
  margin-bottom: 0px;
  margin-left: 10px;
}
.visitReport .visitors .card p{
  margin-bottom: 0px;
  margin-left: 5px;
} */

/* ------------ Visits CSS -----------------*/

.visits .visitsMainCard {
  box-shadow: rgb(204, 219, 232) 2px 2px 6px 2px inset,
    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  border: none;
  border-radius: 12px;
  padding: 0px 10px 10px 10px;
  max-height: 80vh;
  margin-top: 10px;
  overflow-y: scroll;
}

.visits .visitsMainCard::-webkit-scrollbar,
.tbl-fixed::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.visits .visitsMainCard::-webkit-scrollbar-track,
.tbl-fixed::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
  border-radius: 0px;
}

.visits .visitsMainCard::-webkit-scrollbar-thumb,
.tbl-fixed::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

.visits .visitsMainCard .card {
  border: 1px solid #073c7c;
  border-radius: 12px;
  padding: 10px 15px 4px;
  transition: 0.1s;
}

/* ----------------- DROP Cards -------------------*/

.visits .visitsMainCard .drop {
  background: #ff4949;
  color: #ffffff;
  border: none;
  border-radius: 12px;
  padding: 10px 15px 4px;
  transition: 0.1s;
}

.visits .visitsMainCard .drop:hover {
  background: #ff4949 !important;
  color: white !important;
}

/*  -------------------------- NI Cards ------------------ */
.visits .visitsMainCard .ni {
  border: none;
  background: #00c02a;
  color: #ffffff;
  border-radius: 12px;
  padding: 10px 15px 4px;
  transition: 0.1s;
}

.visits .visitsMainCard .ni:hover {
  background: #00c02a !important;
  color: white !important;
}

.visits .visitsMainCard .card .btn-location {
  background: transparent;
  border: 1px solid #073c7c;
  border-radius: 10px;
  font-size: 12px;
  color: #073c7c;
  padding: 4px 8px;
}

.visits .visitsMainCard .card:hover .btn-location {
  background: white;
  border: none;
}

.visits .visitsMainCard .card:hover {
  background: linear-gradient(145deg, #1267cf, #073c7c);
  color: white;
}

.visits .visitsMainCard .card:hover .img {
  filter: invert(100%) sepia(0%) saturate(7491%) hue-rotate(311deg)
    brightness(105%) contrast(102%);
}

.visits .visitsMainCard .card div {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

.visits .visitsMainCard .card div svg {
  margin-right: 6px;
}

.visits .visitsMainCard .card div img {
  margin-right: 7px;
  filter: invert(16%) sepia(94%) saturate(1525%) hue-rotate(195deg)
    brightness(92%) contrast(96%);
}

.visits .visitsMainCard .card div h6 {
  display: block;
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: 600;
}

.visits .visitsMainCard .card div p {
  margin-bottom: 0px;
  margin-left: 10px;
  font-size: 13px;
}

.visitReport .label,
.JaptiKarvai .label,
.ContactRecords .label {
  background: linear-gradient(145deg, #1267cf, #073c7c) !important;
  color: white !important;
  width: max-content;
  padding: 3px 8px;
  border-radius: 0px 0px 8px 8px;
}
.visitReport .label p,
.JaptiKarvai .label p,
.ContactRecords .label p {
  color: white !important;
  margin: 0px !important;
  font-size: 12px !important;
}

.visitReport .searchCard,
.JaptiKarvai .searchCard,
.ContactRecords .searchCard {
  border: none;
  padding-bottom: 12px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
  border-radius: 12px;
}

@media (min-width: 992px) {
  .visitReport .searchCard .parentRow,
  .JaptiKarvai .searchCard .parentRow,
  .ContactRecords .searchCard .parentRow {
    margin-left: -45px;
  }
}

/* ------------------ callLog Report CSS ----------------------- */

.visits .callLabels p {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  font-size: 13px;
}

/* ------- Add useer form ------------*/
.manageRoles p,
.addUser p,
.visitReport p,
.zonalContractor p,
.zonalDistributionHistory p {
  font-size: 12px !important;
  color: red !important;
  margin: 2px 0px 0px 10px !important;
}

/* .manageRoles table tr{
  transition: 0.3s;
}
.manageRoles table tr:hover{
  transform:scale(1.02)
} */

.modal .modal-content {
  border: none;
  border-radius: 12px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.modal .nav {
  border: none;
  border-radius: 14px;
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.15),
    inset 0 0 3px rgba(0, 0, 0, 0.2);
  align-items: center;
  padding: 0px 2px;
  transition: 0.3s;
}

.modal .nav .nav-link {
  border-radius: 14px;
  color: #073c7c;
  padding: 7px 12px;
}

.modal .nav .nav-link.active {
  background: linear-gradient(145deg, #1267cf, #073c7c);
  padding: 4px 12px;
  border: none;
  border-radius: 13px;
  font-weight: 500;
}

.modal .tab {
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15),
    inset 0 0 3px rgba(0, 0, 0, 0.2);
  padding: 30px;
  border-radius: 12px;
}

.manageRoles table tr img {
  filter: invert(13%) sepia(80%) saturate(2360%) hue-rotate(202deg)
    brightness(100%) contrast(95%);
  transition: 0.3s;
}

.manageRoles table tr td img .download {
  width: 54px !important;
}

.manageRoles table tr img:hover {
  transform: translateY(-5px) scale(1.3);
}

.modal-header img {
  filter: invert(13%) sepia(80%) saturate(2360%) hue-rotate(202deg)
    brightness(100%) contrast(95%);
  margin-right: 8px;
}

.switch {
  position: relative;
  display: block;
  vertical-align: top;
  width: 110px;
  height: 30px;
  padding: 3px;
  margin: 0 10px 10px 0;
  background: linear-gradient(to bottom, #eeeeee, #ffffff 25px);
  background-image: -webkit-linear-gradient(top, #eeeeee, #ffffff 25px);
  border-radius: 18px;
  box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.switch-label {
  position: relative;
  display: block;
  height: inherit;
  font-size: 10px;
  text-transform: uppercase;
  background: #eceeef;
  border-radius: inherit;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12),
    inset 0 0 2px rgba(0, 0, 0, 0.15);
}

.switch-label:before,
.switch-label:after {
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  line-height: 1;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
}

.switch-label:before {
  content: attr(data-off);
  right: 11px;
  color: #aaaaaa;
  text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}

.switch-label:after {
  content: attr(data-on);
  left: 8px;
  color: #ffffff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
  opacity: 0;
}

.switch-input:checked ~ .switch-label {
  background: linear-gradient(145deg, #1267cf, #073c7c);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15),
    inset 0 0 3px rgba(0, 0, 0, 0.2);
}

.switch-input:checked ~ .switch-label:before {
  opacity: 0;
}
.switch-input:checked ~ .switch-label:after {
  opacity: 1;
}

.switch-handle {
  position: absolute;
  top: 4px;
  left: 5px;
  width: 28px;
  height: 28px;
  background: linear-gradient(to bottom, #ffffff 40%, #f0f0f0);
  background-image: -webkit-linear-gradient(top, #ffffff 40%, #f0f0f0);
  border-radius: 100%;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}

.switch-handle:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -6px 0 0 -6px;
  width: 12px;
  height: 12px;
  background: linear-gradient(to bottom, #eeeeee, #ffffff);
  background-image: -webkit-linear-gradient(top, #eeeeee, #ffffff);
  border-radius: 6px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
}

.switch-input:checked ~ .switch-handle {
  left: 78px;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

/* ================== Transition ========================== */
.switch-label,
.switch-handle {
  transition: All 0.3s ease;
  -webkit-transition: All 0.3s ease;
  -moz-transition: All 0.3s ease;
  -o-transition: All 0.3s ease;
}

/* 
.manageRoles .inputBox{
  position: relative;
  width: 100%;
}
.manageRoles .inputBox input{
  width: 100%;
  padding:10px;
  border:1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  outline: none;
  font-size: 1em;
}
.manageRoles .inputBox span{
  position: absolute;
  left: 0;
  pointer-events: none;
  padding: 10px;
  font-size: 1em;
  color: #000000;
  transition: 0.3s;
  font-weight: 300;
}

.manageRoles .inputBox input:focus ~ span{
  color: #073c7c;
  transform: translateX(15px) translateY(-7px);
  font-size: 0.85em;
  padding: 0 10px;
  background: #FFFFFF;
  letter-spacing: 0.1em;
  font-weight: 600;
}
.manageRoles .inputBox input:focus{
  border: 1px solid #073c7c;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.tbl-container {
  max-width: fit-content;
  max-height: fit-content;
  padding: 10px;
}

.tbl-container table {
  padding: 0px;
  margin: 0px;
}

.tbl-container .tbl-fixed {
  overflow-y: scroll;
  height: fit-content;
  /* max-height: 65vh; */
  margin: 0px;
  /* margin-top: 50px; */
}
.tbl-container table {
  min-width: 100%;
  border-collapse: separate;
  max-width: -moz-fit-content;
  max-width: fit-content;
}
.table-responsive-lg {
  padding: 0px !important;
}

.tbl-container table th {
  position: sticky;
  top: 0px;
  margin-top: 10px;
  text-align: left;
  padding-left: 12px;
  vertical-align: middle;
  background-color: #073c7c;
  color: #ffffff;
  font-weight: 500;
  z-index: 1;
}
.tbl-container table tr {
  text-align: left !important;
}

.tbl-container table td {
  z-index: -2;
}

tfoot tr {
  max-width: max-content;
}
table tfoot {
  position: sticky;
  background-color: #073c7c;
  color: #ffffff;
  inset-block-end: 0;
  top: 0px;
}

.distribution .refresh,
.zonalDistribution .add,
.targetReport .refresh {
  filter: invert(13%) sepia(80%) saturate(2360%) hue-rotate(202deg)
    brightness(100%) contrast(95%);
  width: 25px;
  height: 25px;
  transition: 0.2s;
}

.distribution .refresh:hover,
.zonalDistribution .add:hover,
.targetReport .refresh:hover {
  transform: translateY(-2px) scale(1.3);
}

.ChartCard {
  border: none !important;
  border-radius: 12px;
  /* box-shadow: rgba(17, 12, 46, 0.15) 20px 20px 60px 0px; */
  box-shadow: rgb(204, 219, 232) 2px 2px 6px 2px inset,
    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  background: #ffffff;
  text-align: center;
  justify-content: center;
}

.ChartCard h6 {
  font-weight: 600;
}

.refresh {
  transition: 0.3s;
  cursor: pointer;
}

.refresh:hover {
  transform: scale(1.29) !important;
}

.Home .welcome p {
  font-size: 14px;
}

.ChartCard p {
  text-align: start;
  border-radius: 0px 5px 5px 0px;
  color: #ffffff;
  width: max-content;
  padding: 0px 5px;
  margin: 0px;
  font-size: 13px;
}

.distribution .nav,
.CallSummary .nav,
.JaptiKarvaiZoneReport .nav,
.DialedCallLog .nav,
.TotalCalling .nav,
.visitReport .nav {
  border: none;
  border-radius: 12px;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  box-shadow: rgb(204, 219, 232) 2px 2px 6px 2px inset,
    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  width: max-content;
  margin-left: auto;
}

.distribution .nav .nav-link,
.CallSummary .nav .nav-link,
.JaptiKarvaiZoneReport .nav .nav-link,
.DialedCallLog .nav .nav-link,
.TotalCalling .nav .nav-link,
.visitReport .nav .nav-link {
  border-radius: 12px;
  color: #073c7c;
  padding: 6px 12px;
}

.distribution .nav .nav-link.active,
.CallSummary .nav .nav-link.active,
.JaptiKarvaiZoneReport .nav .nav-link.active,
.DialedCallLog .nav .nav-link.active,
.visitReport .nav .nav-link.active,
.TotalCalling .nav .nav-link.active {
  background: linear-gradient(145deg, #1267cf, #073c7c);
  padding: 6px 12px;
  border: none;
  border-radius: 12px;
  font-weight: 500;
}
/* .distribution .tab{
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  padding: 30px;
  border-radius: 12px;
} */

/* .Home .btn-log img{
  transition: 0.3s;
  width: 17px;
}

.Home .btn-log img:nth-child(1){
  margin-right: -12px;
}

.Home .btn-log:hover img:nth-child(1){
  transform: translateX(-8px);
} */

.zonWiseConReport .scrollable-tabs-container {
  background: linear-gradient(0deg, #073c7c, #1267cf) !important;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.scrollable-tabs-container svg {
  width: 40px;
  height: 40px;
  padding: 4px;
  cursor: pointer;
  color: #ffffff;
  transition: 0.2s;
}

.scrollable-tabs-container svg:hover {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
}

.zonWiseConReport .scrollable-tabs-container ul {
  display: flex !important;
  gap: 16px;
  padding: 12px 24px;
  margin: 0;
  list-style: none;
  overflow-x: scroll !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

.zonWiseConReport .scrollable-tabs-container ul::-webkit-scrollbar {
  display: none;
}

.searchCard {
  padding: 0px 14px 14px !important;
  border: none !important;
  border-radius: 8px !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.searchCard .searchLabel {
  background: linear-gradient(145deg, #1267cf, #073c7c) !important;
  width: max-content !important;
  color: var(--white) !important;
  font-weight: 500 !important;
  text-align: center !important;
  margin: auto auto 12px auto !important;
  border-radius: 0px 0px 8px 8px !important;
  padding: 2px 8px !important;
  font-size: 13px !important;
}

.zonWiseConReport .scrollable-tabs-container li {
  color: #ffffff !important;
  text-decoration: none;
  /* background: #333 ; */
  padding: 4px 24px;
  display: inline-block;
  border-radius: 10px;
  white-space: nowrap;
  cursor: pointer;
}

.zonWiseConReport .scrollable-tabs-container li.active {
  background: #ffffff;
  color: #000000 !important;
}

.zonWiseConReport .scrollable-tabs-container .left-arrow,
.zonWiseConReport .scrollable-tabs-container .right-arrow {
  position: absolute;
  height: 100%;
  width: 100px;
  top: 0;
  display: flex;
  align-items: center;
  padding: 0 10px;
  display: none;
}

.zonWiseConReport .scrollable-tabs-container .right-arrow {
  right: 0;
  background: linear-gradient(to left, #134f99 50%, transparent);
  justify-content: flex-end;
}

.zonWiseConReport .scrollable-tabs-container .left-arrow {
  background: linear-gradient(to right, #134f99 50%, transparent);
}

.zonWiseConReport .scrollable-tabs-container .left-arrow.active,
.zonWiseConReport .scrollable-tabs-container .right-arrow.active {
  display: flex;
}

.modal .modal-content .data {
  display: flex !important;
  align-items: center;
  margin-bottom: 15px;
}

.modal .modal-content .data svg {
  color: #004686;
}

.modal .modal-content .data h6,
.modal .modal-content .data p {
  margin-bottom: 0px;
}

.modal .modal-content .data p {
  margin-left: 6px;
}

.InternalDev .card {
  border: none;
  border-radius: 12px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 20px 0px;
}

.InternalDev .card h5 {
  font-weight: 600;
}

/* ---------------- Caller Css ------------ */

.CallerNav {
  left: 0;
  padding: 6px 18px;
}

.PropertyCalling .accordion-item .accordion-header {
  background: var(--primary);
  border-radius: 10px 10px 0px 0px;
}

.PropertyCalling .accordion .accordion-button::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.PropertyCalling .PropertyCard {
  border: 1px solid var(--primary);
  border-radius: 8px;
  padding: 8px 12px;
  background: transparent;
  height: 100%;
  gap: 10px;
}

.PropertyCalling .PropertyCard div {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 6px;
}

/*  ---------- User Card Skeleton Loader CSS ---------- */

.UserCardSkeleton {
  background: white;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  position: relative;
}

.UserCardSkeleton .row {
  width: 100%;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  display: flex !important;
}

/* .UserCardSkeleton .row span{
  width: 5%;
  height: 22px;
  background: #ededed; 
  border-radius: 50%;
  border-bottom: 1px solid #ddd;
  background-color: #ededed;
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #ededed;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
} */

.UserCardSkeleton .leftLabel {
  position: absolute;
  top: 0;
  left: 0;
  width: 15%;
  height: 20px;
  border-radius: 8px 0px 8px 0px;
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #ededed;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1.5s loading ease-in-out infinite;
}

.UserCardSkeleton .rightLabel {
  position: absolute;
  top: 0;
  right: 0;
  width: 15%;
  height: 20px;
  border-radius: 0px 8px 0px 8px;
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #ededed;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1.5s loading ease-in-out infinite;
}

.UserCardSkeleton .row .data {
  width: 100%;
  /* background: #ededed; */
  height: 22px;
  border-radius: 4px;
  border-bottom: 1px solid #ddd;
  background-color: #ededed;
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #ededed;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1.5s loading ease-in-out infinite;
}

.UserCardSkeleton .btn {
  width: 18%;
  height: 25px;
  border-radius: 8px;
  margin: 0 auto;
  background-color: #ededed;
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #ededed;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}

@keyframes loading {
  to {
    background-position-x: -40%;
  }
}

.PropertyCalling .userCard {
  display: flex;
  padding: 30px 20px 24px 20px;
  margin-bottom: 24px;
  position: relative;
  border-radius: 8px;
  border: none;
}

.PropertyCalling .userCard p {
  font-size: 13px;
  margin-bottom: 4px;
}

.PropertyCalling .userCard:last-child {
  margin-bottom: 0px;
}

.PropertyCalling .userCard .defaulter {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px 0px 8px 0px;
  background: red;
  color: white;
  padding: 4px 10px;
  font-size: 11px;
  width: max-content;
  margin: auto;
}

.PropertyCalling .userCard .PerWatchBtn {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0px 8px 0px 8px;
  color: white;
  padding: 4px 10px;
  font-size: 11px;
  width: max-content;
  margin: auto;
}

.PropertyCalling .userCard .callBtn {
  /* position: absolute;
  bottom: 0;
  right: 0; */
  background: #008d09;
  color: white;
  padding: 4px 10px;
  font-size: 12px;
  width: max-content;
  margin: auto;
  border-radius: 8px;
}

.pagination {
  width: 100% !important;
}

.pagination .prev,
.pagination .prev:focus,
.pagination .next,
.pagination .next:focus {
  background: transparent !important;
  border: none !important;
  color: var(--FoxBlack) !important;
  transition: 0.3s !important;
}

.pagination .prev:hover {
  background: transparent;
  border: none;
  color: var(--FoxBlack);
  transform: translateX(-5px);
}

.pagination .next:hover {
  background: transparent;
  border: none;
  color: var(--Black);
  transform: translateX(5px);
}

.pagination .activePageButton {
  border: 1px solid var(--Black) !important;
  color: var(--Black) !important;
  background: var(--White) !important;
  transition: 0.1s !important;
  font-weight: 600;
}

.pagination .pageButton {
  border: 1px solid transparent !important;
  color: var(--Black) !important;
  background: transparent !important;
  transition: 0.1s !important;
}

.pagination select.form-select {
  border: 1px solid #6f6f6f;
  transition: 0.3s;
}

/* Delete Users Screen CSS */
.deleteUsers {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: 600;
}

/* Visit Report Data */

.table-wrapper {
  overflow-y: scroll;
  overflow-x: scroll;
  /* height: fit-content; */
  margin-top: 5px;
  padding-bottom: 0px;
  padding: 0px !important;
}

.table-wrapper::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.table-wrapper::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 0px;
}

.table-wrapper::-webkit-scrollbar-thumb {
  background-color: #93c0ff;
  border-radius: 6px;
  border: 1px solid transparent;
}

.outer-wrapper table {
  width: 100%;
  min-width: max-content;
  border-collapse: separate;
  border-spacing: 0px;
}

.outer-wrapper table th {
  z-index: 1 !important;
  position: sticky;
  top: 0px;
  background: hsl(212, 100%, 88%);
  color: var(--FoxBlack);
  text-align: left;
  font-weight: 700;
  font-size: 15px;
  outline: 0.7px solid transparent;
  border: 1.5px solid transparent;
}

.outer-wrapper table th,
.outer-wrapper table td {
  padding: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.outer-wrapper table td {
  text-align: left;
  font-size: 15px;
  border: 0.5px solid rgba(137, 137, 137, 0.1);
  padding-left: 10px;
}

table tfoot {
  position: sticky;
  inset-block-end: 0;
  top: 0px;
}

.deleteCard {
  width: 60% !important ;
  background-color: #c74a4a !important;
  color: white !important;
  margin-left: 10 !important;
}

.backButton {
  background-color: #004686 !important;
  color: white !important;
}

.logout-div {
  /* align-self: flex-end; */
  justify-self: flex-end;
}

.mobile-res{
  width: 100%;
  height: 100%;
}

.red-row{
  background-color: #ffb3b3;
}

.reject-line{
  display: inline-block;
  padding: 10px;
  background-color: #c34141; /* Lighter red color */
  color: white;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


@media (max-width: 768px) {
  .deleteCard {
    width: 100% !important ;
  }

  .property-div {
    flex-wrap: wrap;
  }

  .mobile-res{
    width: 200%;
  }

  .modal-body{
    height: 500px;
    overflow-y: scroll;
  }

}

/* .PropertyCalling .PropertyCalling .accordion-item .accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
} */
.Analytics .frame{
  position: relative;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding-top: 30px; 
  border-radius: 12px;
  margin-bottom: 50px;
}

.Analytics .frame .frameFooter{
  position: absolute;
  bottom: 0;
  width: 98%;
  height:70px;
  background: white;
  display: flex;
  justify-content: space-between;
  padding: 0px 30px;
  align-items: center;
}

.Analytics .frame .frameFooter h5{
  margin-bottom: 0px;
}

.Analytics .frame .frameFooter p{
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  transition: 0.3s;
  cursor: pointer;
}

.Analytics .frame .frameFooter p:hover{
  color: var(--primary);
  transform: scale(1.09);
}


.ViewFullScreen{
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  position: relative;
}

.PowerBIFrame{
  position: relative;
}

.ViewFullScreen .customLoader,
.PowerBIFrame .customLoader{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--primary); /* Just for visual reference */
  color: var(--white);
  width: 110px;
  height: 110px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
